<div #ddm="ngbDropdown" ngbDropdown placement="bottom-right" (openChange)="!$event && touch()">
    <div class="input-group">
        <div
            class="form-control"
            (click)="$event.preventDefault(); ddm.open()"
            [class.ng-invalid]="!ngControl.valid"
            [class.ng-valid]="ngControl.valid"
            [class.ng-dirty]="!ngControl.pristine"
            [class.ng-pristine]="ngControl.pristine"
            [class.ng-touched]="ngControl.touched"
            [class.ng-untouched]="!ngControl.touched"
            [class.errors]="view.vars?.errors?.length"
        >
            {{ inputValue ? (inputValue | str2Date | date: 'mediumDate') : '' }}
        </div>

        <div class="input-group-append" *ngIf="!view.vars.disabled">
            <button class="btn btn-outline-gray no-caret" ngbDropdownToggle type="button">
                <i class="fa fa-calendar"></i>
            </button>
        </div>
    </div>

    <div class="custom-calendar" ngbDropdownMenu *ngIf="!view.vars.disabled">
        <div class="inner">
            <div class="top p-2 px-3 border-bottom-1 d-lg-none">
                <span class="text-muted m-0">{{ view.vars.label || view.vars.name | ucfirst }}</span>
                <span class="text-muted float-right">Select date</span>
            </div>

            <ngb-datepicker
                #dp
                [(ngModel)]="inputValue"
                (ngModelChange)="onInputChange(); ddm.close()"
                [minDate]="{ year: 1900, month: 1, day: 1 }"
                [maxDate]="{ year: now.getFullYear() + 5, month: 12, day: 31 }"
            ></ngb-datepicker>

            <div class="d-flex actions p-2">
                <div class="btn btn-outline-danger" (click)="clear(); ddm.close()">Clear</div>

                <div class="btn-group ml-auto">
                    <div class="btn btn-outline-gray" (click)="selectToday(); ddm.close()">Today</div>
                    <div class="btn btn-gray" (click)="ddm.close()">Done</div>
                </div>
            </div>
        </div>
    </div>
</div>
