<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <sf-form
            name="form0"
            rowClasses="col-12"
            [view]="form.view"
            [(ngModel)]="form.model"
            [horizontal]="true"
            [wide]="true"
            [fields]="['type', 'date', 'start', 'end', 'inPerson', 'remote']"
        ></sf-form>
        <sf-form
            [class.hide]="!form.model.inPerson"
            name="form1"
            rowClasses="col-12"
            [view]="form.view"
            [(ngModel)]="form.model"
            [horizontal]="true"
            [wide]="true"
            [fields]="['inPersonCapacity', 'inPersonDetails']"
        ></sf-form>
        <sf-form
            [class.hide]="!form.model.remote"
            name="form2"
            rowClasses="col-12"
            [view]="form.view"
            [(ngModel)]="form.model"
            [horizontal]="true"
            [wide]="true"
            [fields]="['remoteCapacity', 'remoteDetails']"
        ></sf-form>

        <sf-form name="formrest" rowClasses="col-12" [view]="form.view" [(ngModel)]="form.model" [horizontal]="true" [wide]="true"></sf-form>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="error"></ngxh6-loading-or-error></ng-template>
