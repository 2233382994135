<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <ngxh6-tabs
            class="mt-5"
            *ngIf="navItems"
            [containerClass]="'tab-container p-4 mb-3'"
            [navItems]="navItems"
            [content]="tabContent"
            [key]="'form-tab'"
            [small]="true"
        ></ngxh6-tabs>

        <ng-template #tabContent let-id="id">
            <ng-container *ngIf="id === 'basic'">
                <fieldset ngModelGroup="basic" #basicForm="ngModelGroup">
                    <sf-form
                        name="form1"
                        [view]="form.view"
                        [(ngModel)]="form.model"
                        [horizontal]="true"
                        [wide]="true"
                        [fields]="[
                            'photo',
                            'title',
                            'firstName',
                            'lastName',
                            'email',
                            'mobile',
                            'phone',
                            'phoneExt',
                            'preferredMethod',
                            'organisation'
                        ]"
                    ></sf-form>
                </fieldset>
            </ng-container>

            <ng-container *ngIf="id === 'account'">
                <sf-form
                    #accountForm
                    name="form"
                    rowClasses="col-12"
                    [view]="form.view"
                    [(ngModel)]="form.model"
                    [horizontal]="true"
                    [wide]="true"
                    [fields]="['username', 'roles', 'locked', 'plainPassword']"
                ></sf-form>
            </ng-container>
        </ng-template>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="error"></ngxh6-loading-or-error></ng-template>
