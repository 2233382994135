import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DecisionTreeInterface } from '../../../../_generated/api_interfaces/api/decisionTree.interface';
import { DecisionTreeItemInterface } from '../../../../_generated/api_interfaces/api/decisionTreeItem.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ApiErrorResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';

export type DecisionTreeResponses = Array<IndexedDecisionTreeItems>;
export interface IndexedDecisionTreeItems {
    [id: string]: ReturnType<DecisionTreeItemInterface['getDecisionTreeItemItem']>['data'];
}
export enum Outcome {
    positive,
    negative,
    extremeNegative,
}

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizComponent implements OnInit {
    // For handling errors from the API reponse
    public error: ApiErrorResponse<any>;

    // Observable property along with the property to hold the detail
    public response$: Observable<ReturnType<DecisionTreeInterface['getDecisionTreeItem']>>;
    public response: ReturnType<DecisionTreeInterface['getDecisionTreeItem']>;

    // Holds the decision tree config
    public decisionTree: ReturnType<DecisionTreeInterface['getDecisionTreeItem']>['data'] | null;

    // This holds the next item Id as determined by the user's response, it can be null
    public nextItemId: string | null;

    // This holds the currently active item
    public currentItem: ReturnType<DecisionTreeItemInterface['getDecisionTreeItemItem']>['data'] | null = null;

    // This array holds all decision tree items
    private itemList: Array<IndexedDecisionTreeItems> | null = [];

    // This holds the user's responses to each item
    private decisionTreeResponses: Array<ReturnType<DecisionTreeItemInterface['getDecisionTreeItemItem']>['data']> = [];

    private validAnswers: { [key: string]: string } = {
        positive: 'No',
        negative: 'Yes',
        skip: 'Skip',
    };

    // This holds which decision tree Id is to be show, it's value is set in the data parameter of the crm router
    private decisionTreeId: string;

    // This holds the starting item, set in the decision tree config
    private startingItemId: string;

    // Determines whether to show the leaf outcome text
    public showLeafOutcome: boolean = false;

    // Used to determine if quiz has started
    public quizStarted: boolean = false;

    // Used to distinguish between the public quiz and the logged in quiz
    public publicQuiz: boolean = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        readonly router: Router,
        private cdRef: ChangeDetectorRef,
        readonly route: ActivatedRoute,
    ) {
        if (this.route.snapshot.data['publicQuiz']) {
            this.publicQuiz = true;
        }
    }

    ngOnInit(): void {
        // Retrieve decision tree data
        this.getData();
    }

    // Start the quiz
    start() {
        this.quizStarted = true;

        // Refresh the view
        this.cdRef.detectChanges();
    }

    // Handle 'Yes' responses, this could be positive Likert responses or a literal 'Yes' including custom labels
    yes(): void {
        this.currentItem.answer = this.validAnswers.negative;
        this.handleResponse();
    }

    // Handle 'No' responses, this includes 'Never' or 'No' including custom labels
    no(): void {
        this.currentItem.answer = this.validAnswers.positive;
        this.handleResponse();
    }

    // Handle skips, skips are an implied 'No', but it is recorded separately
    skip(): void {
        this.currentItem.answer = this.validAnswers.skip;
        this.handleResponse();
    }

    handleResponse(): void {
        // Record user's response
        this.recordResponse();

        // Determine 'next' item
        if (this.currentItem.answer == this.validAnswers.negative) {
            this.nextItemId = this.currentItem.yesItemId !== null ? this.currentItem.yesItemId : null;
        } else {
            this.nextItemId = this.currentItem.noItemId !== null ? this.currentItem.noItemId : null;
        }

        // Check if there is a 'next' item, if not, this might be because one or both Yes/ No items are null
        if (this.nextItemId) {
            this.currentItem = Object.assign({}, this.itemList[this.nextItemId]);
        } else {
            // Check if item is the final item, and it has leaf conclusion text
            if (this.isFinalItem() && this.currentItem.leafConclusionText) {
                // Show leaf conclusion text
                this.showLeafOutcome = true;
            }
        }
    }

    // Check if the current item is the final item
    isFinalItem(): boolean {
        let isFinalItem: boolean = false;

        // Check if item has been answered and there is no next item Id
        if (this.currentItem.answer && !this.nextItemId) {
            isFinalItem = true;
        }

        return isFinalItem;
    }

    showOutcome(): string {
        let outcome: Outcome;
        let outcomeText: string = '';

        // Calculate the user's outcome
        outcome = this.calculateOutcome();

        // Determine which outcome text to display based on the calculated outcome
        switch (outcome) {
            case Outcome.positive:
                outcomeText = this.decisionTree.positiveConclusionText;
                break;
            case Outcome.negative:
                outcomeText = this.decisionTree.negativeConclusionText;
                break;
            case Outcome.extremeNegative:
                outcomeText = this.decisionTree.extremeNegativeConclusionText;
        }

        return outcomeText;
    }

    calculateOutcome(): Outcome {
        // Set the default outcome to positive
        let outcome: Outcome = Outcome.positive;

        // Set defaults for negative outcomes
        let negativeOutcome: boolean = false;
        let extremeNegativeOutcome: boolean = false;

        // Loop through the user's responses to determine outcome
        for (let response of this.decisionTreeResponses) {
            // Do not calculate score if item is functional
            if (!response.functionalItem) {
                // Inverse responses if item requires it, unless answer was skipped
                if (response.inverseYesNoCount && response.answer != this.validAnswers.skip) {
                    response.answer = response.answer == this.validAnswers.negative ? this.validAnswers.positive : this.validAnswers.negative;
                }

                // Check for 'Yes' responses
                if (response.answer == this.validAnswers.negative) {
                    // Check if item is a trigger item, mark as an extreme negative
                    if (response.triggerItem) {
                        extremeNegativeOutcome = true;
                    } else {
                        negativeOutcome = true;
                    }
                }
            }
        }

        // Set the outcome based on the worst answer descending
        if (extremeNegativeOutcome) {
            outcome = Outcome.extremeNegative;
        } else if (negativeOutcome) {
            outcome = Outcome.negative;
        }

        return outcome;
    }

    // Handle Reset, put the current item back to the starting item from the decision tree
    reset(): void {
        // Mark the quiz as not started
        this.quizStarted = false;

        // Reset leaf outcomes flag
        this.showLeafOutcome = false;

        // Reset the decision tree responses
        this.decisionTreeResponses = [];

        // Check if the starting item exists
        if (this.itemList && this.startingItemId && this.itemList[this.startingItemId]) {
            // Set the current item to the starting item
            this.currentItem = Object.assign({}, this.itemList[this.startingItemId]);

            // Refresh the view
            this.cdRef.detectChanges();
        }
    }

    private recordResponse(): void {
        this.decisionTreeResponses.push(this.currentItem);
    }

    private getData(): void {
        // Retrieve the decision tree slug from the current page URL basename, this value matches the slug property in the Decision Tree entity
        let slug: string = this.router.url.split('/').pop();

        this.response$ = this.api
            .get({
                url: 'v1/decision_tree_retrieval?slug=' + slug,
                displayErrors: false,
                useCache: false,
                refresh: true,
                skipAuth: true,
            })
            .pipe(
                // Transform the decision tree items into an indexed array
                map((res: ReturnType<DecisionTreeInterface['getDecisionTreeItem']>) => {
                    // Retrieve decision tree config
                    this.decisionTree = res.data;

                    // Set the starting item Id, if one exists
                    if (this.decisionTree.startingItem) {
                        this.startingItemId = this.decisionTree.startingItem.id;

                        // Create a new key/ value object using the item Id as the key
                        res.data.decisionTreeItems.map((itemRes: ReturnType<DecisionTreeItemInterface['getDecisionTreeItemItem']>['data']) => {
                            this.itemList[itemRes.id] = itemRes;
                            return itemRes;
                        });
                    }

                    return res;
                }),
            );
        // Subscribe and reset decision tree
        this.response$.subscribe(() => this.reset());
    }
}
