<ng-container *ngIf="form.view; else loadingOrError">
    <div class="font-weight-light">
        <p>For any questions or urgent referrals please call <a href="tel:0889721712">08 8972 1712</a></p>
        <p>
            Providing this information helps us to appropriately respond to client needs, however it is ok if you are not able to complete all information
            if not appropriate/possible.
        </p>
    </div>
    <hr />
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <h5>Referring Service Details</h5>
        <ng-container *ngIf="!form.editId">
            <sf-form
                name="form"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['reasonForReferral', 'hasCourtCase', 'courtDate', 'clientReferralServicePermission']"
            ></sf-form>
            <hr />
            <h5>Client Details</h5>
            <sf-form
                name="form"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="[
                    'clientFirstName',
                    'clientLastName',
                    'clientAlias',
                    'dob',
                    'address',
                    'suburb',
                    'state',
                    'postcode',
                    'safeMail',
                    'safeVisit',
                    'phone',
                    'safeCall',
                    'safeTextVoicemail',
                    'alternativeContactName',
                    'alternativeContactDetails',
                    'alternativeContactRelationship',
                    'otherServicesInvolved',
                    'otherPeopleInvolved',
                    'childrensDetails',
                    'childrenWithClient',
                    'whereChildrenLive',
                    'otherServicesWorkingWithClient',
                    'aboriginalTorresStraitIslander',
                    'interpreterNeeded',
                    'language',
                    'disability',
                    'disabilityType',
                    'otherInfo',
                    'clientKwilsPermission',
                    'noKwilsPermission'
                ]"
            ></sf-form>
        </ng-container>

        <ng-container *ngIf="!readonly">
            <hr *ngIf="!options.sticky_actions && options.actions_separator" />
            <ngxh6-form-actions
                [form]="form"
                [options]="options"
                [formRef]="formRef"
                [valid]="formRef.valid"
                (cancel)="cancel()"
                (delete)="delete()"
                (archive)="archive($event)"
            ></ngxh6-form-actions>
        </ng-container>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="form.error"></ngxh6-loading-or-error></ng-template>
