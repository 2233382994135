import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import packageInfo from '../../../../../../../package.json';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { FormServiceInterface, OfflineQueue, OfflineServiceInterface, UserAccountInterface, UserServiceInterface } from '@hutsix/ngxh6';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
    public env: string = environment.env;
    public version: string = packageInfo.version + (environment.env === 'staging' ? '-staging' : '');
    public _user: { loggedIn: boolean; account?: UserAccountInterface };
    public offlineQueue$: Observable<OfflineQueue[]>;

    constructor(
        public router: Router,
        @Inject('UserService') public userService: UserServiceInterface,
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('OfflineService') public offline: OfflineServiceInterface,
    ) {
        this.offlineQueue$ = this.offline.getOfflineQueue();
    }

    update(): void {
        this.offlineQueue$ = this.offline.getOfflineQueue();
    }
}
