import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { fadeInOut, fadeInOut2 } from '../../../../../../animations';
import { ApiResponse, DefaultFormComponent, FormServiceInterface, NavItem, UserServiceInterface, ValueAccessorBase } from '@hutsix/ngxh6';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModelGroup } from '@angular/forms';
import { UserInterface } from '../../../../../../_generated/api_interfaces/api/user.interface';

@Component({
    selector: 'app-course-session-form',
    templateUrl: './course-session-form.component.html',
    styleUrls: ['./course-session-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseSessionFormComponent extends DefaultFormComponent {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        super(formService, userService, location, cdRef);
    }
}
