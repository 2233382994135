import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { fadeInOut, fadeInOut2 } from '../../../../../../animations';
import { ApiResponse, DefaultFormComponent, FormServiceInterface, NavItem, UserServiceInterface, ValueAccessorBase } from '@hutsix/ngxh6';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModelGroup } from '@angular/forms';
import { UserInterface } from '../../../../../../_generated/api_interfaces/api/user.interface';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent extends DefaultFormComponent implements AfterViewChecked, AfterViewInit {
    @ViewChildren('basicForm') basicForm: QueryList<NgModelGroup>;
    @ViewChildren('accountForm') accountForm: QueryList<ValueAccessorBase>;

    public navItems: NavItem[];

    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        super(formService, userService, location, cdRef);
    }

    ngAfterViewInit(): void {
        this.navItems = [
            { id: 'basic', label: 'Basic info', destroyOnHide: false, watch: this.basicForm },
            { id: 'account', label: 'Account', destroyOnHide: false, watch: this.accountForm },
        ];
    }

    ngAfterViewChecked(): void {
        if (this.form && this.formRef) {
            this.form.formRef = this.formRef;
        }
    }
}
