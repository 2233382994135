import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, ViewChild } from '@angular/core';
import { UntypedFormControl, NgControl, NgModel, Validator } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ValueAccessorBase } from '@hutsix/ngxh6';

/**
 * String Time adapter
 */
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
    fromModel(value: string | null): NgbTimeStruct | null {
        if (!value) {
            return null;
        }
        const split = value.split(':');
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: parseInt(split[2], 10),
        };
    }

    toModel(time: NgbTimeStruct | null): string | null {
        return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-time',
    templateUrl: './sf-form-time.component.html',
    styleUrls: ['./sf-form-time.component.scss'],
    providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormTimeComponent extends ValueAccessorBase implements Validator {
    @ViewChild('formInput', { static: true }) formInput: NgModel;

    public inputValue: string;
    public timeValue: string;

    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        private calendar: NgbCalendar,
        private dateAdapter: NgbDateAdapter<string>,
    ) {
        super(cdRef, ngControl);
    }

    writeValue(value: any): void {
        if (value === undefined || value === null) return;

        const d = value instanceof Date ? value : new Date(value);
        if (d.getTime()) {
            value =
                d.getHours().toString().padStart(2, '0') +
                ':' +
                d.getMinutes().toString().padStart(2, '0') +
                ':' +
                d.getSeconds().toString().padStart(2, '0');
        }

        this.ngControl.viewToModelUpdate(value);
        this.timeValue = value;
        super.writeValue(value);
    }

    openChange($e): void {
        if ($e) return;
        this.touch();
        this.inputValue = this.timeValue;
        this.onInputChange();
    }

    selectNow(): void {
        this.writeValue(new Date());
    }

    clear(): void {
        this.timeValue = this.dateAdapter.toModel(null);
    }

    validate(c: UntypedFormControl = null): any {
        if (this.view.vars.required && !this.inputValue) {
            return ['Value is required'];
        }
        return super.validate(c);
    }
}
