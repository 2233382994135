import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, ViewChild } from '@angular/core';
import { UntypedFormControl, NgControl, NgModel, Validator } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ValueAccessorBase } from '@hutsix/ngxh6';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '-';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        const d = date?.day.toString().padStart(2, '0');
        const m = date?.month.toString().padStart(2, '0');
        const y = date?.year;
        return date ? y + this.DELIMITER + m + this.DELIMITER + d : '';
    }
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';

    fromModel(value: any | null): NgbDateStruct | null {
        if (value instanceof NgbDate) {
            return value;
        } else if (value instanceof Date) {
            return new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
        } else if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
            const date = value.split('-');
            return new NgbDate(Number(date[0]), Number(date[1]), Number(date[2]));
        } else if (typeof value === 'string') {
            const date = new Date(value);
            return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        const d = date?.day.toString().padStart(2, '0');
        const m = date?.month.toString().padStart(2, '0');
        const y = date?.year;

        return date ? y + this.DELIMITER + m + this.DELIMITER + d : null;
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-date',
    templateUrl: './sf-form-date.component.html',
    styleUrls: ['./sf-form-date.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormDateComponent extends ValueAccessorBase implements Validator {
    @ViewChild('formInput', { static: true }) formInput: NgModel;

    public inputValue: string;
    public now = new Date();

    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        private calendar: NgbCalendar,
        private dateAdapter: NgbDateAdapter<string>,
    ) {
        super(cdRef, ngControl);
    }

    public selectToday(): void {
        this.inputValue = this.dateAdapter.toModel(this.calendar.getToday());
        setTimeout(() => this.onInputChange(), 20);
    }

    public clear(): void {
        this.inputValue = this.dateAdapter.toModel(null);
        setTimeout(() => this.onInputChange(), 20);
    }

    validate(c: UntypedFormControl = null): any {
        if (this.view?.vars?.required && !this.inputValue) {
            return ['Value is required'];
        }
        return super.validate(c);
    }
}
