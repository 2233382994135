import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './routes/user/login/login.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ReloadPageComponent } from './routes/reload-page/reload-page.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { QuizComponent } from './modules/_shared/routes/quiz/quiz.component';
import { SelfServiceReferralComponent } from './modules/_shared/routes/self-service-referral/self-service-referral.component';
import { StartPageComponent } from './modules/_shared/routes/start-page/start-page.component';

const routes: Routes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'start-page', component: StartPageComponent },
    { path: 'ssq', component: QuizComponent, data: { publicQuiz: true } },
    { path: 'ssr', component: SelfServiceReferralComponent },
    // { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },
    { path: '', canActivate: [AuthGuardService], component: DashboardComponent },

    {
        path: 'crm',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_STAFF'] },
        loadChildren: () => import('./modules/crm/crm.module').then(m => m.CrmModule),
    },
    {
        path: 'account',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_ORG_STAFF'] },
        loadChildren: () => import('./modules/stakeholder/stakeholder.module').then(m => m.StakeholderModule),
    },
    {
        path: 'kb',
        loadChildren: () => import('./modules/kb/kb.module').then(m => m.KbModule),
    },
    {
        path: 'user-admin',
        loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
