<div class="container h-100 d-flex flex-column">
    <div class="text-center py-2">
        <a class="btn btn-secondary fixed-top quick-exit" href="https://www.greendress.au/" target="_top">Close</a>
        <a routerLink="../start-page"><img class="mt-5" src="/assets/logo-large.svg" alt="App Logo" /></a>
    </div>
    <ng-container *ngIf="!formSubmitted; else successPageContent">
        <h4 class="mt-2 mb-4 flex-grow text-center">Self Service Referral</h4>
        <p>
            If you would like someone from KWILS to get in contact with you to talk about a legal problem, please complete the referral form below.

            Please only provide a phone number or email address that is safe for us to contact you on.
        </p>
        <ngxh6-form-embed
            [options]="{ sticky_actions: false, show_back: true }"
            [formName]="'self_service_referral'"
            (submitted)="successPage()"
        ></ngxh6-form-embed>
    </ng-container>
    <ng-template #successPageContent>
        <div class="button-container">
            <div class="flex-column text-center">
                <p class="mt-2">
                    Thank you for providing your details. KWILS will be in contact with you shortly.
                </p>
                <button class="btn btn-gray mr-2" routerLink="../start-page">Back</button>
                <a class="btn btn-secondary" href="https://www.greendress.au/" target="_top">Close</a>
            </div>
        </div>
    </ng-template>
</div>
