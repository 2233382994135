import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import { CourseSessionFormComponent } from '../modules/_shared/components/forms/custom-forms/course-session-form/course-session-form.component';
import { ReferralFormComponent } from '../modules/_shared/components/forms/custom-forms/referral-form/referral-form.component';

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
  CourseSessionFormComponent: CourseSessionFormComponent,
  ReferralFormComponent: ReferralFormComponent,
};

export const forms: { [key: string]: FormOptions } = {
  user: new FormOptions({
    route: 'crm/form/user',
    object_name: 'User',
    api_get: 'user/form',
    api_refresh_endpoint: 'v1/users',
    success_route: 'crm/users/{id}',
    component: 'UserFormComponent',
  }),
  message: new FormOptions({
    object_name: 'Message',
    cache_strategy: 'performance',
    object_class: 'App\\Entity\\Message',
    allow_edits: false,
    create_success_string: 'Message Sent!',
  }),
  notes: new FormOptions({
    object_name: 'Note',
    api_get: 'notes/form',
    api_refresh_endpoint: 'v1/note_lists',
    success_route: null,
  }),
  profile: new FormOptions({
    object_name: 'Profile',
    route: 'profile',
    api_get: 'user/profile',
    create_string: 'Edit Profile',
    success_route: 'profile',
    display: 'horizontal-wide',
  }),
  organisations: new FormOptions({
    object_name: 'Organisation',
    object_class: 'App\\Entity\\Organisation',
    allow_archive: false,
    display: 'horizontal-wide',
    modal_display: 'horizontal',
    modal_size: 'lg',
  }),
  staff: new FormOptions({
    route: 'account/form/staff',
    object_name: 'User',
    api_get: 'staff/form',
    api_refresh_endpoint: 'v1/users',
    success_route: 'account/staff',
    display: 'horizontal-wide',
  }),
  course_spec: new FormOptions({
    object_class: 'App\\Entity\\CourseSpec',
    object_name: 'Course Type',
    api_refresh_endpoint: 'v1/course_specs',
    display: 'horizontal-wide',
    modal_display: 'horizontal-wide',
  }),
  course_session: new FormOptions({
    object_class: 'App\\Entity\\CourseSession',
    object_name: 'Course',
    api_refresh_endpoint: ['v1/course_sessions', 'v1/course_sessions/upcoming', 'v1/course_sessions/past'],
    display: 'horizontal-wide',
    modal_display: 'horizontal',
    component: 'CourseSessionFormComponent',
  }),
  course_participants: new FormOptions({
    api_get: 'course/participant',
    object_name: 'Course Participants',
    modal_size: 'lg',
    edit_string: 'Add Course Participants',
    edit_success_string: 'Participants added',
  }),
  register_individual: new FormOptions({
    api_get: 'course/register_individual',
    object_name: 'Registration',
  }),
  register_organisation: new FormOptions({
    api_get: 'course/register_organisation',
    object_name: 'Registration',
  }),
  referral: new FormOptions({
    object_name: 'Referral',
    api_post: 'referral/form',
    offline_edit: true,
    offline_create: true,
    allow_archive: true,
    object_class: 'App\\Entity\\Referral',
    component: 'ReferralFormComponent',
    create_success_string: 'Referral Sent',
  }),
  decision_tree: new FormOptions({
    object_class: 'App\\Entity\\DecisionTree',
    object_name: 'Decision Tree',
    api_refresh_endpoint: 'v1/decision_trees',
    display: 'horizontal',
    modal_display: 'horizontal',
  }),
  decision_tree_item: new FormOptions({
    object_class: 'App\\Entity\\DecisionTreeItem',
    object_name: 'Decision Tree Item',
    api_refresh_endpoint: 'v1/decision_trees_items',
    display: 'horizontal-wide',
    modal_display: 'horizontal-wide',
    modal_size: 'xl',
    allow_archive: true,
  }),
  referral_log: new FormOptions({
    object_name: 'Referral Log',
    object_class: 'App\\Entity\\ReferralLog',
    api_refresh_endpoint: 'v1/referrals',
    allow_edits: true,
    allow_archive: true,
    offline_edit: true,
    offline_readonly: true,
    success_route: 'referrals',
  }),
  self_service_referral: new FormOptions({
    api_get: 'self-service-referral/form',
    api_post: 'self-service-referral/form',
    create_success_string: 'Referral Sent',
  }),
};
