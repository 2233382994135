<div class="container h-100" [ngClass]="!publicQuiz ? 'd-flex align-items-center justify-content-center' : ''">
    <div *ngIf="publicQuiz" class="text-center py-2 block">
        <a class="btn btn-secondary fixed-top" href="https://www.greendress.au/" target="_top">Close</a>
        <a routerLink="../start-page"><img class="mt-5 mb-2" src="/assets/logo-large.svg" alt="App Logo" /></a>
    </div>
    <ng-container *ngIf="response$ | async as response; else loadingOrError">
        <div class="flex-column">
            <ng-container *ngIf="currentItem">
                <ng-container *ngIf="quizStarted || !decisionTree.introduction; else introductionText">
                    <ng-container *ngIf="!showLeafOutcome; else leafOutcome">
                        <ng-container *ngIf="isFinalItem()"><div class="card card-body text-center" [innerHTML]="showOutcome()"></div></ng-container>
                        <ng-container *ngIf="!currentItem.answer">
                            <div class="my-1 text-center">
                                <h3 class="my-3">
                                    {{ currentItem.title }}
                                    <ng-container *ngIf="currentItem.helpText">
                                        <span class="icon">
                                            <i class="fa fa-fw fa-2x fa-info-circle" [ngbTooltip]="currentItem.helpText"></i>
                                        </span>
                                    </ng-container>
                                </h3>
                            </div>
                            <!-- Description Card -->
                            <ng-container *ngIf="currentItem.description">
                                <div class="my-3">
                                    <div class="card">
                                        <div class="card-body text-center" [innerHTML]="currentItem.description"></div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="button-container">
                                <div class="flex-column">
                                    <!-- is not a yes/no Item -->
                                    <ng-container *ngIf="!currentItem.yesNoItem">
                                        <button class="btn btn-primary" (click)="yes()">Always</button>
                                        <button class="btn btn-primary" (click)="yes()">Sometimes</button>
                                        <button class="btn btn-primary" (click)="yes()">Rarely</button>
                                        <button class="btn btn-primary" (click)="no()">Never</button>
                                    </ng-container>
                                    <!-- is a yes/no Item -->
                                    <ng-container *ngIf="currentItem.yesNoItem">
                                        <!-- Uses a label if label exists, else defaults to Yes -->
                                        <button
                                            class="btn btn-primary"
                                            (click)="yes()"
                                            [innerHTML]="currentItem.yesLabel ? currentItem.yesLabel : 'Yes'"
                                        ></button>
                                        <!-- Uses a label if label exists, else defaults to No -->
                                        <button
                                            class="btn btn-primary"
                                            (click)="no()"
                                            [innerHTML]="currentItem.noLabel ? currentItem.noLabel : 'No'"
                                        ></button>
                                    </ng-container>
                                    <!-- is a skip item -->
                                    <ng-container *ngIf="!currentItem.noSkip">
                                        <button class="btn btn-info" (click)="skip()">Skip this question</button>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <!-- Conclusion Text -->
                <ng-template #leafOutcome>
                    <div class="my-3">
                        <div class="card card-body text-center" [innerHTML]="currentItem.leafConclusionText"></div>
                    </div>
                </ng-template>
            </ng-container>
            <!-- Quiz Start -->
            <ng-template #introductionText>
                <div class="flex-column">
                    <!-- Decision Tree Name -->
                    <div class="my-1 text-center">
                        <h3>{{ decisionTree.name }}</h3>
                    </div>
                    <br />
                    <!-- If decision tree intro -->
                    <ng-container *ngIf="decisionTree.introduction">
                        <div class="my-1">
                            <div class="card card-body text-center" [innerHTML]="decisionTree.introduction"></div>
                        </div>
                    </ng-container>
                    <!-- Start Button -->
                    <div class="button-container mt-4">
                        <button class="btn btn-primary" (click)="start()">Start</button>
                    </div>
                </div>
            </ng-template>
            <div class="button-container mt-4">
                <button *ngIf="publicQuiz && !quizStarted" routerLink="../start-page" class="btn btn-gray">Back</button>
                <button *ngIf="quizStarted" class="btn btn-danger" (click)="reset()">Start over</button>
            </div>
        </div>
    </ng-container>
    <ng-template #loadingOrError><ngxh6-loading-or-error [error]="error"></ngxh6-loading-or-error></ng-template>
</div>
