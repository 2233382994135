import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-self-service-referral',
    templateUrl: './self-service-referral.component.html',
    styleUrls: ['./self-service-referral.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfServiceReferralComponent {
    formSubmitted: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private cdRef: ChangeDetectorRef) {}
    successPage(): void {
        // Form has been submitted, show the success message
        this.formSubmitted = true;
        this.cdRef.detectChanges();
    }
}
