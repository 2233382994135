<div class="collection-container">
    <div
        *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex"
        [@fadeIn]="!!item.__created"
        [class.dim]="fade === i"
        class="collection-rows mb-2"
    >
        <form #itemForm="ngForm" ngForm class="position-absolute invisible">
            <sf-form
                [view]="$any(view.children)[i] || view?.vars?.prototype"
                name="value"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [horizontal]="false"
            ></sf-form>
        </form>

        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center flex-grow-1" *ngIf="inputValue[i]?.filename">
                <ngxh6-fileicon class="mr-2" [small]="true" [file]="inputValue[i]"></ngxh6-fileicon>
                <a class="flex-grow-1" target="_blank" [href]="inputValue[i].path">{{ inputValue[i].originalFilename }}</a>
                <small class="ml-1 text-muted flex-shrink-0 text-nowrap ml-sm-auto">{{ +inputValue[i].size | bytes: 1 }}</small>
            </div>

            <div class="collection-remove-wrapper ml-auto">
                <button
                    type="button"
                    class="btn ml-2 btn-danger collection-remove"
                    *ngIf="view?.vars?.allow_delete"
                    (click)="removeItem(i)"
                    (mouseover)="fade = i"
                    (mouseleave)="fade = null"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="d-flex" *ngIf="view?.vars?.allow_add">
    <div class="dz-container">
        <dropzone
            #drpzone
            class="btn-lg btn btn-outline-primary"
            [useDropzoneClass]="false"
            [message]="'Drop files or click here to upload'"
            (uploadProgress)="onUploadProgress($event)"
            (error)="onUploadError($event)"
            (addedFile)="onAddedFile($event)"
            (sending)="onSending($event)"
            (success)="onUploadSuccess($event)"
            [config]="DROPZONE_CONFIG"
        >
        </dropzone>

        <ng-container *ngIf="uploading > 0">
            <div class="progress mt-2">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [ngStyle]="{ width: uploadProgress + '%' }"></div>
            </div>
            <div class="d-flex mt-1 align-items-center">
                <small
                    >Uploading {{ uploading }} {{ 'file' | makePluralString: uploading }}. {{ this.bytesDownloaded | bytes: 1 }}/{{
                        this.bytesTotal | bytes: 1
                    }}</small
                >
                <div class="btn btn-sm btn-danger ml-auto" (click)="cancelUpload()">Cancel</div>
            </div>
        </ng-container>
    </div>
</div>
