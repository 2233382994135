import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceInterface } from '@hutsix/ngxh6';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    constructor(@Inject('UserService') public user: UserServiceInterface, private router: Router) {}

    ngOnInit(): void {
        if (this.user.isGranted('ROLE_STAFF')) {
            this.router.navigate(['/crm']);
        } else {
            this.router.navigate(['/account']);
        }
    }
}
