<div [class.disabled]="!offline.isConnected" [ngbTooltip]="!offline.isConnected ? 'File upload unavailable when offline' : ''">
    <form #itemForm="ngForm" ngForm class="position-absolute invisible">
        <sf-form [view]="view" name="value" [(ngModel)]="inputValue" [renderAll]="true" (ngModelChange)="onInputChange()"></sf-form>
    </form>

    <div class="d-flex" [class.hidden]="uploading || inputValue?.filename">
        <div class="dz-container">
            <dropzone
                #drpzone
                class="btn btn-outline-primary"
                [useDropzoneClass]="false"
                [message]="'Drag or click to upload'"
                (uploadProgress)="onUploadProgress($event)"
                (error)="onUploadError($event)"
                (addedFile)="onAddedFile($event)"
                (sending)="onSending($event)"
                (success)="onUploadSuccess($event)"
                [config]="DROPZONE_CONFIG"
            >
            </dropzone>
        </div>

        <div class="btn btn-gray ml-auto" (click)="fieldReset()" *ngIf="!uploading && originalValue?.filename !== inputValue?.filename">
            <i class="fa fa-refresh"></i>
        </div>
    </div>

    <div class="progress-wrapper" *ngIf="uploading">
        <div class="progress" style="height: 25px">
            <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                [style]="{ width: uploadProgress + '%' }"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <small class="text-muted">Uploading... </small><a href="javascript:void(0)" class="btn btn-sm btn-gray" (click)="reset(false)">cancel</a>
    </div>

    <div class="preview d-flex" *ngIf="!uploading && inputValue?.filename">
        <div class="image mr-2 flex-shrink-0">
            <!--      <ng-container *ngIf="uploadPreview">-->
            <!--        &lt;!&ndash; Todo: grab preview from dz if available &ndash;&gt;-->
            <!--      </ng-container>-->
            <ng-container *ngIf="!uploadPreview">
                <div class="file-icon">
                    <ngxh6-fileicon [small]="true" [file]="inputValue"></ngxh6-fileicon>
                </div>
            </ng-container>
        </div>
        <div class="details flex-grow-1 d-flex align-items-center">
            <small>
                <a target="_blank" [href]="inputValue.path">{{ inputValue.filename }}</a>
                <!--      <span class="ml-1">(<strong>{{+inputValue.size|bytes: 1}}</strong>)</span>-->
            </small>
        </div>
        <div class="dz-reset ml-2 d-flex align-items-center flex-shrink-0">
            <div class="btn btn-gray mr-2" (click)="fieldReset()" *ngIf="!uploading && originalValue?.filename !== inputValue?.filename">
                <i class="fa fa-refresh"></i>
            </div>

            <div (click)="reset(true)" class="btn btn-outline-danger">
                <i class="fa fa-close"></i>
            </div>
        </div>
    </div>
</div>
