<div class="container h-100 d-flex flex-column">
    <div class="text-center py-2">
        <a class="btn btn-secondary fixed-top quick-exit" href="https://www.greendress.au/" target="_top">Close</a>
        <img class="mt-5" src="/assets/logo-large.svg" alt="App Logo" />
    </div>
    <p class="mt-2">
        The Katherine Women’s Information and Legal Service (KWILS) is a legal service that helps women in the Katherine and Big Rivers region.
        Please click the buttons below to either complete a referral form if you would like us to contact you, or complete our Relationship Quiz.
    </p>
    <div class="text-center">
        <a routerLink="../ssq" class="btn btn-primary mr-1">Quiz</a>
        <a routerLink="../ssr" class="btn btn-primary">Self Referral</a>
    </div>
</div>
