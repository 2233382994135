import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewChildren,
} from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { fadeInOut, fadeInOut2 } from '../../../../../../animations';
import { ApiResponse, DefaultFormComponent, FormServiceInterface, NavItem, UserServiceInterface, ValueAccessorBase } from '@hutsix/ngxh6';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralInterface } from '../../../../../../_generated/api_interfaces/api/referral.interface';

@Component({
    selector: 'app-referral-form',
    templateUrl: './referral-form.component.html',
    styleUrls: ['./referral-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralFormComponent extends DefaultFormComponent implements AfterViewChecked {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        super(formService, userService, location, cdRef);
    }

    ngAfterViewChecked(): void {
        if (this.form && this.formRef) {
            this.form.formRef = this.formRef;
        }
    }
}
